import './postcard.css';

interface PostcardProps {
    rotateDirection: string;
    imagePath: string;
    title: string;
    date: string;
}

function Postcard(prop: PostcardProps) {
  return (
    <div className={`Postcard ${ prop.rotateDirection }`}>
      <img src={`small/${prop.imagePath}`} alt={`Postcard of ${prop.title}`}></img>
      <div className='PostcardInfo'>
        <h1>{prop.title}</h1>
        <h3>{prop.date}</h3>
      </div>
    </div>

  );
}

export default Postcard;
