import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import './post.css'

const Post = () => {
  const [postContent, setPostContent] = useState("");
  let { title } = useParams();

  useEffect(() => {
    fetch(`/post-content/${title}.md`)
      .then((response) => response.text())
      .then((text) => setPostContent(text));
  }, []);

  return (
    <div>
        <ReactMarkdown children={postContent}/>
    </div>
  );
}

export default Post;
