import './home.css'
import { Link } from 'react-router-dom';
import Postcard from '../../components/postcard/postcard';

const postcards=[
    {date: "October 2022", title: "Kyoto", image: "kyoto", rotateDirection: "Left-rotate", hasPost: false},
    {date: "September 2022", title: "Yamanashi", image: "yamanashi", rotateDirection: "Right-rotate", hasPost: false},
    {date: "August 2022", title: "Kumamoto", image: "kumamoto", rotateDirection: "Left-rotate", hasPost: false},
    {date: "July 2022", title: "Otake-san", image: "otake", rotateDirection: "Right-rotate", hasPost: false},
    {date: "July 2022", title: "Osaka Roadtrip", image: "osaka", rotateDirection: "Left-rotate", hasPost: false},
    {date: "June 2022", title: "Takao-san", image: "takao", rotateDirection: "Right-rotate", hasPost: false},
    {date: "May 2022", title: "Tokyo", image: "tokyo", rotateDirection: "Left-rotate", hasPost: false},
    {date: "May 2022", title: "Oita", image: "beppu", rotateDirection: "Right-rotate", hasPost: false},
    {date: "July 2021", title: "Isle of Skye", image: "isle_of_skye", rotateDirection: "Left-rotate", hasPost: false},
    {date: "June 2021", title: "Birchington", image: "birchington", rotateDirection: "Right-rotate", hasPost: false},
  ]

function Home() {
    return (
      <div>
        <div className="Section-centered">
        <h1 className="Section-title">Postcards Of The World</h1>
        <h3>...a history of the places I've seen...</h3>
        {/* <button className="Button-inline">Explore the postcards</button>
        <button className="Button-inline">Take me to the map</button> */}
      </div>
      <ul className="Flex-container">
        {postcards.map(postcard => {
          return (
            <li>
                {postcard.hasPost?
                    <Link to={'post/' + postcard.image} className='Post-link'>
                        <Postcard date={postcard.date} title={postcard.title} imagePath={`/${postcard.image}.jpg`} rotateDirection={postcard.rotateDirection}/>
                    </Link>
                : 
                    <Postcard date={postcard.date} title={postcard.title} imagePath={`/${postcard.image}.jpg`} rotateDirection={postcard.rotateDirection}/>
                }

            </li>
          )
        })}
      </ul>
      </div>
    );
  }
  
  export default Home;