import './reset.css'
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Post from './components/post/post';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img width="200px" src="/postcardsoftheworld.svg" alt="Postcards of the World logo"/>
      </header>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="post/:title" element={<Post/>} />
      </Routes>
    </div>
  );
}

export default App;
